import React from 'react';
import whatsappImg from '../assests/whatsapp.png';

const Contact = () => {
  return (
    <div>
      <h1>Sales and Support</h1>
      <ul>
        <li>Opening hours: 10AM - 8PM Monday - Friday</li>
        <li>Orders will be dispatched on the next working day</li>
        <li>Orders placed on Friday will be dispatched on Monday</li>
        <br /><b>Contact</b>
        <li>The quickest way to get a reply is through the messenger<br />
          <a style={{ textDecoration: 'underline' }} href="https://www.facebook.com/profile.php?id=100094593693297" target="_blank">SilverTau Facebook page</a>
        </li>
        <li>or Whatsapp / Text / Call<br />
          <strong><a href="tel:+447828194734">07828 194 734</a></strong>
          <br />
          <a aria-label="Chat on WhatsApp" href="https://wa.me/+447828194734?text=Hello%2C%20I%20have%20a%20question"> <img style={{ width: '10rem' }} alt="Chat on WhatsApp" src={whatsappImg} /></a>
        </li>
        <li>alternatively send us an email<br />
          <a style={{ textDecoration: 'underline' }} href="mailto:contact@silvertau.co.uk">contact@silvertau.co.uk</a>
        </li>
      </ul>
    </div>
  );
};

export default Contact;
