export const USER_AUTH_START = 'USER_AUTH_START';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const USER_AUTH_FAIL = 'USER_AUTH_FAIL';
export const RESET = 'RESET';
export const LOGOUT = 'LOGOUT';

export const USER_REGISTER_START = 'USER_REGISTER_START';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const USERLIST_FETCH_START = 'USERLIST_FETCH_START';
export const USERLIST_FETCH_SUCCESS = 'USERLIST_FETCH_SUCCESS';
export const USERLIST_FETCH_FAIL = 'USERLIST_FETCH_FAIL';

export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

export const USER_EDIT_START = 'USER_EDIT_START';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL';
export const USER_EDIT_RESET = 'USER_EDIT_RESET';

export const USER_FETCH_START = 'USER_FETCH_START';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAIL = 'USER_FETCH_FAIL';
export const USER_FETCH_RESET = 'USER_FETCH_RESET';

export const FORGOT_PASSWORD_SEND_START = 'FORGOT_PASSWORD_SEND_START';
export const FORGOT_PASSWORD_SEND_SUCCESS = 'FORGOT_PASSWORD_SEND_SUCCESS';
export const FORGOT_PASSWORD_SEND_FAIL = 'FORGOT_PASSWORD_SEND_FAIL';
export const FORGOT_PASSWORD_SEND_RSET = 'FORGOT_PASSWORD_SEND_RSET';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';
