import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showLogo } from './taulogo';
import SearchBox from '../SearchBox/SearchBox';
import * as routes from '../../constants/routes';
import '../style.css';

const Header = () => {
  const userAuthData = useSelector((state) => state.userLogin);
  const cart = useSelector((state) => state.cart);
  const history = useHistory();

  const { cartItems } = cart;
  const { userInfo } = userAuthData;
  const goHome = () => history.push(`/`);
  
  setTimeout(() => showLogo(), 100)

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
            <div id="tau-logo" style={{ display: 'inline-block' }} onClick={goHome}></div>
{/*          <LinkContainer to={routes.HOME}>
            <Navbar.Brand>SilverTau</Navbar.Brand>
          </LinkContainer>*/}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <SearchBox />
            <Nav className="ml-auto">
              <LinkContainer to={routes.HOME}>
                <Nav.Link>
                  <i className="fas fa-shop"></i>SHOP
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={routes.CARE}>
                <Nav.Link>
                  <i className="fas fa-hand-sparkles"></i>CARE
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={routes.CONTACT} style={{ marginRight: '100px' }}>
                <Nav.Link>
                  <i className="fas fa-envelope"></i>{' '}CONTACT
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to={routes.CART}>
                <Nav.Link>
                  <i className="fas fa-shopping-cart"></i>CART
                  {cartItems.length > 0 && (
                    <span className="cart">{cartItems.reduce((acc, item) => acc + item.qty, 0)}</span>
                  )}
                </Nav.Link>
              </LinkContainer>

              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to={routes.PROFILE}>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={routes.LOGOUT}>
                    <NavDropdown.Item>Logout</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              ) : (
                <LinkContainer to={routes.LOGIN}>
                  <Nav.Link>
                    <i className="fas fa-user"></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.role === 'admin' && (
                <NavDropdown title="Admin" id="adminMenu">
                  <LinkContainer to={routes.USERS}>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={routes.PRODUCTS}>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={routes.ORDERS}>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
