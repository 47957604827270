export const HOME = '/';
export const LOGIN = '/login';
export const CARE = '/care';
export const CONTACT = '/contact';
export const ABOUT = '/about';
export const RESET_PASSWORD = '/reset-password';
export const FORGOT_PASSWORD = '/forgot-passsword';
export const EMAIL_VERIFICATION = '/email-verification';
export const LOGOUT = '/logout';
export const REGISTER = '/register';
export const PRODUCT = '/product/:productId';
export const CART = '/cart';
export const SHIPPING = '/shipping';
export const PAYMENT = '/payment';
export const PLACE_ORDER = '/place-order';
export const ORDER = '/order/:orderId';
export const PROFILE = '/profile';
export const ORDERS = '/admin/order-list';
export const PRODUCTS = '/admin/product-list';
export const PRODUCT_EDIT = '/admin/product/:productId/edit';
export const USERS = '/admin/user-list';
export const USER_EDIT = '/admin/user/:userId/edit';
