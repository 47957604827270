import React from 'react';

const Care = () => {
  return (
    <div>
      <h1>Looking after your silver</h1>
      <ul>
        <li>Keeping the coin in the capsule can help protect it from damage and oxidation caused by exposure to air and moisture.</li>
        <li>When handling the coin, wearing gloves can help prevent oils and dirt from your hands from transferring to the coin and potentially causing damage or altering its appearance.</li>
        <li>If a silver coin becomes tarnished, dipping it in thiaurea can help to remove the tarnish and restore its appearance.</li>
      </ul>
    </div>
  );
};

export default Care;
